import { fetchGroupList } from "@/api/group";

export default {
  namespaced: true,
  state: {
    groupList: []
  },

  getters: {
    groupList(state) {
      return state.groupList;
    }
  },

  actions: {
    async refreshGroupList({ commit, rootGetters }) {
      let userGuid = rootGetters["user/userGuid"];
      let groupList = await fetchGroupList(userGuid);
      commit("SET_GROUP_LIST", groupList);
    }
  },

  mutations: {
    SET_GROUP_LIST(state, groupList) {
      state.groupList = groupList;
    }
  }
};
