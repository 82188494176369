export default {
  namespaced: true,
  state: {
    // 测量时，固定为 customer
    userEntity: "customer",
    userGuid: "",
    userDispName: "",
    deptGuid: "",
    deptName: "",
    groupGuid: "",
    /**
     * [{
     *  text: lb DisplayName,
     *  value: lb Guid,
     *  order: test order
     * }]
     */
    testLbList: [],
    /**
     * [{
     *  lbId: lbId,
     *  lbDispName: lbDispName,
     *  caseGuid: submittedCaseGuid,
     * }]
     */
    submittedCaseList: [],
    /**
     * [{
     *  lbId: lbId,
     *  lbDispName: lbDispName,
     *  caseGuid: submittedCaseGuid,
     * }]
     */
    savedCaseList: [],
    /**
     * [{
     *  lbId: lbId,
     *  lbDispName: lbDispName,
     *  caseGuid: submittedCaseGuid,
     * }]
     */
    cancelledCaseList: [],
    currentLbGuid: "",
    /**
     * [ Answer Number ]
     */
    savedAnsList: [],
    personGuid: "",
    timeCost: 0
  },

  getters: {
    userEntity(state) {
      return state.userEntity;
    },
    userGuid(state) {
      return state.userGuid || sessionStorage.getItem("userGuid") || "";
    },
    userDispName(state) {
      return state.userDispName || sessionStorage.getItem("userDispName") || "";
    },
    deptGuid(state) {
      return state.deptGuid || sessionStorage.getItem("deptGuid") || "";
    },
    deptName(state) {
      return state.deptName || sessionStorage.getItem("deptName") || "";
    },
    groupGuid(state) {
      return state.groupGuid || sessionStorage.getItem("groupGuid") || "";
    },
    testLbList(state) {
      if (!state.testLbList || !state.testLbList.length) {
        let storedTestLbList = sessionStorage.getItem("testLbList");
        return storedTestLbList ? JSON.parse(storedTestLbList) : [];
      }
      return state.testLbList;
    },
    submittedCaseList(state) {
      if (!state.submittedCaseList || !state.submittedCaseList.length) {
        let storedCaseList = sessionStorage.getItem("submittedCaseList");
        return storedCaseList ? JSON.parse(storedCaseList) : [];
      }
      return state.submittedCaseList;
    },
    savedCaseList(state) {
      if (!state.savedCaseList || !state.savedCaseList.length) {
        let storedCaseList = sessionStorage.getItem("savedCaseList");
        return storedCaseList ? JSON.parse(storedCaseList) : [];
      }
      return state.savedCaseList;
    },
    cancelledCaseList(state) {
      if (!state.cancelledCaseList || !state.cancelledCaseList.length) {
        let storedCaseList = sessionStorage.getItem("cancelledCaseList");
        return storedCaseList ? JSON.parse(storedCaseList) : [];
      }
      return state.cancelledCaseList;
    },
    currentLbGuid(state) {
      return (
        state.currentLbGuid || sessionStorage.getItem("currentLbGuid") || ""
      );
    },
    savedAnsList(state) {
      if (!state.savedAnsList || !state.savedAnsList.length) {
        let storedAnsList = sessionStorage.getItem("savedAnsList");
        return storedAnsList ? JSON.parse(storedAnsList) : [];
      }
      return state.savedAnsList;
    },
    personGuid(state) {
      return state.personGuid || sessionStorage.getItem("personGuid") || "";
    },
    timeCost(state) {
      return state.timeCost || sessionStorage.getItem("timeCost") || 0;
    }
  },

  actions: {
    initScaState(
      { commit },
      { userGuid, userDispName, groupGuid, testLbList }
    ) {
      commit("SET_USER_GUID", userGuid);
      commit("SET_USER_DISP_NAME", userDispName);
      commit("SET_GROUP_GUID", groupGuid);
      commit("SET_TEST_LB_LIST", testLbList);
    },
    clearScaState({ commit }) {
      commit("SET_USER_GUID", "");
      commit("SET_USER_DISP_NAME", "");
      commit("SET_DEPT_GUID", "");
      commit("SET_DEPT_NAME", "");
      commit("SET_GROUP_GUID", "");
      commit("SET_TEST_LB_LIST", []);
      commit("SET_SUBMITTED_CASE_LIST", []);
      commit("SET_SAVED_CASE_LIST", []);
      commit("SET_CANCELLED_CASE_LIST", []);
      commit("SET_CURRENT_LB_GUID", "");
      commit("SET_SAVED_ANS_LIST", []);
      commit("SET_PERSON_GUID", "");
      sessionStorage.clear();
    },
    setPersonGuid({ commit }, personGuid) {
      commit("SET_PERSON_GUID", personGuid);
    },
    setDeptGuid({ commit }, deptGuid) {
      commit("SET_DEPT_GUID", deptGuid);
    },
    setDeptName({ commit }, deptName) {
      commit("SET_DEPT_NAME", deptName);
    },
    setTestLbList({ commit }, testLbList) {
      commit("SET_TEST_LB_LIST", testLbList);
    },
    setCurrentLbGuid({ commit }, currentLbGuid) {
      commit("SET_CURRENT_LB_GUID", currentLbGuid);
    },
    saveAnsList({ commit }, ansList) {
      commit("SET_SAVED_ANS_LIST", ansList);
    },
    clearAnsList({ commit }) {
      commit("SET_SAVED_ANS_LIST", []);
    },
    addToSubmittedCaseList({ commit, getters }, newCaseObj) {
      let newCaseList = [...getters.submittedCaseList];
      newCaseList.push(newCaseObj);
      commit("SET_SUBMITTED_CASE_LIST", newCaseList);
    },
    addToSavedCaseList({ commit, getters }, newCaseObj) {
      let newCaseList = [...getters.savedCaseList];
      newCaseList.push(newCaseObj);
      commit("SET_SAVED_CASE_LIST", newCaseList);
    },
    addToCancelledCaseList({ commit, getters }, newCaseObj) {
      let newCaseList = [...getters.cancelledCaseList];
      newCaseList.push(newCaseObj);
      commit("SET_CANCELLED_CASE_LIST", newCaseList);
    },
    setTimeCost({ commit }, newTimeCost) {
      commit("SET_TIME_COST", newTimeCost);
    },
    clearTimeCost({ commit }) {
      commit("SET_TIME_COST", 0);
    }
  },

  mutations: {
    SET_USER_GUID(state, userGuid) {
      state.userGuid = userGuid;
      sessionStorage.setItem("userGuid", userGuid);
    },
    SET_USER_DISP_NAME(state, userDispName) {
      state.userDispName = userDispName;
      sessionStorage.setItem("userDispName", userDispName);
    },
    SET_DEPT_GUID(state, deptGuid) {
      state.deptGuid = deptGuid;
      sessionStorage.setItem("deptGuid", deptGuid);
    },
    SET_DEPT_NAME(state, deptName) {
      state.deptName = deptName;
      sessionStorage.setItem("deptName", deptName);
    },
    SET_GROUP_GUID(state, groupGuid) {
      state.groupGuid = groupGuid;
      sessionStorage.setItem("groupGuid", groupGuid);
    },
    SET_TEST_LB_LIST(state, testLbList) {
      state.testLbList = [...testLbList];
      sessionStorage.setItem("testLbList", JSON.stringify(testLbList));
    },
    SET_SUBMITTED_CASE_LIST(state, submittedCaseList) {
      state.submittedCaseList = [...submittedCaseList];
      sessionStorage.setItem(
        "submittedCaseList",
        JSON.stringify(submittedCaseList)
      );
    },
    SET_SAVED_CASE_LIST(state, savedCaseList) {
      state.savedCaseList = [...savedCaseList];
      sessionStorage.setItem("savedCaseList", JSON.stringify(savedCaseList));
    },
    SET_CANCELLED_CASE_LIST(state, cancelledCaseList) {
      state.cancelledCaseList = [...cancelledCaseList];
      sessionStorage.setItem(
        "cancelledCaseList",
        JSON.stringify(cancelledCaseList)
      );
    },
    SET_CURRENT_LB_GUID(state, currentLbGuid) {
      state.currentLbGuid = currentLbGuid;
      sessionStorage.setItem("currentLbGuid", currentLbGuid);
    },
    SET_SAVED_ANS_LIST(state, ansList) {
      state.savedAnsList = [...ansList];
      sessionStorage.setItem("savedAnsList", JSON.stringify(ansList));
    },
    SET_PERSON_GUID(state, personGuid) {
      state.personGuid = personGuid;
      sessionStorage.setItem("personGuid", personGuid);
    },
    SET_TIME_COST(state, timeCost) {
      state.timeCost = timeCost;
      sessionStorage.setItem("timeCost", timeCost);
    }
  }
};
