import { fetchDeptList } from "@/api/dept";

export default {
  namespaced: true,
  state: {
    deptList: []
  },

  getters: {
    deptList(state) {
      return state.deptList;
    }
  },

  actions: {
    async refreshDeptList({ commit, rootGetters }) {
      let userGuid = rootGetters["user/userGuid"];
      let userEntity = rootGetters["user/userEntity"];
      let deptList = await fetchDeptList(userEntity, userGuid);
      commit("SET_DEPT_LIST", deptList);
    }
  },

  mutations: {
    SET_DEPT_LIST(state, deptList) {
      state.deptList = deptList;
    }
  }
};
