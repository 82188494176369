import { getJwtToken, setJwtToken } from "@/utils/cookie";
import { fetchCredentialRole } from "@/api/credential";

export default {
  namespaced: true,
  state: {
    jwt: getJwtToken(),
    guid: "",
    dispName: "",
    entity: "",
    credentialGuid: "",
    credentialName: "",
    roles: []
  },

  getters: {
    jwt(state) {
      return state.jwt;
    },
    userGuid(state) {
      return state.guid || sessionStorage.getItem("userGuid") || "";
    },
    userDispName(state) {
      return state.dispName || sessionStorage.getItem("userDispName") || "";
    },
    userEntity(state) {
      return state.entity || "";
    },
    credentialGuid(state) {
      return (
        state.credentialGuid || sessionStorage.getItem("credentialGuid") || ""
      );
    },
    credentialName(state) {
      return (
        state.credentialName || sessionStorage.getItem("credentialName") || ""
      );
    },
    roles(state) {
      return state.roles || [];
    }
  },

  actions: {
    setLoginInfo({ commit }, { jwt, guid, credentialGuid, credentialName }) {
      commit("SET_JWT", jwt);
      commit("SET_USER_GUID", guid);
      commit("SET_CREDENTIAL_GUID", credentialGuid);
      commit("SET_CREDENTIAL_NAME", credentialName);
      setJwtToken(jwt);
    },
    clearLoginInfo({ commit }) {
      commit("SET_JWT", null);
      commit("SET_USER_GUID", null);
      commit("SET_DISP_NAME", null);
      commit("SET_ENTITY", null);
      commit("SET_CREDENTIAL_GUID", null);
      commit("SET_CREDENTIAL_NAME", null);
      commit("SET_ROLES", []);
      setJwtToken("");
    },
    setUserDispName({ commit }, userDispName) {
      commit("SET_DISP_NAME", userDispName);
    },
    async reloadUserEntityRoles({ commit, getters }) {
      var entityRole = await fetchCredentialRole(getters.credentialGuid);
      commit("SET_ENTITY", entityRole.entity);
      commit("SET_ROLES", [entityRole.role]);
      return {
        entity: entityRole.entity,
        roles: [entityRole.role]
      };
    }
  },

  mutations: {
    SET_JWT(state, jwt) {
      state.jwt = jwt;
    },
    SET_USER_GUID(state, userGuid) {
      state.guid = userGuid;
      sessionStorage.setItem("userGuid", userGuid);
    },
    SET_DISP_NAME(state, dispName) {
      state.dispName = dispName;
      sessionStorage.setItem("userDispName", dispName);
    },
    SET_ENTITY(state, entity) {
      state.entity = entity;
    },
    SET_CREDENTIAL_GUID(state, credentialGuid) {
      state.credentialGuid = credentialGuid;
      sessionStorage.setItem("credentialGuid", credentialGuid);
    },
    SET_CREDENTIAL_NAME(state, credentialName) {
      state.credentialName = credentialName;
      sessionStorage.setItem("credentialName", credentialName);
    },
    SET_ROLES(state, roles) {
      state.roles = roles;
    }
  }
};
