export function getJwtToken() {
  return getCookie("skjwt");
}

export function setJwtToken(token) {
  setCookie("skjwt", token);
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2)
    return parts
      .pop()
      .split(";")
      .shift();
}

function setCookie(name, value) {
  document.cookie = `${name}=${value};path=/;`;
}
