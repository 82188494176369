import { get, post } from "@/utils/axios";

export async function fetchCredentialRole(credentialGuid) {
  let response = await get(
    `/credential/CredentialRoleFromGuid/${credentialGuid}`
  );
  return {
    entity: response.entity,
    role: response.role
  };
}

export async function fetchLoginName(credentialGuid) {
  let response = await get("/credential/CredentialNameFromEntityGuid", {
    t: new Date().getTime(),
    credentialGuid
  });
  return response.value;
}

export function fetchEntityCredentialList(entityGuid) {
  return get(`/credential/CredentialListFromEntityGuid/${entityGuid}`);
}

export async function isLoginNameDuplidated(loginName) {
  let response = await post("/credential/CheckLoginNameDuplicated", {
    value: loginName
  });
  return response.value;
}

export async function createOneCredential({
  credentialName,
  loginName,
  password,
  description,
  roleGuid,
  entity,
  entityGuid
}) {
  let response = await post("/credential/CreateOneCredential", {
    credentialName,
    loginName,
    password,
    description,
    roleGuid,
    entity,
    entityGuid
  });
  return response.guid;
}

export async function updateCredentialInfo(
  credentialGuid,
  { credentialName, loginName, description, roleGuid }
) {
  let response = await post(
    `/credential/SaveCredentialInfo/${credentialGuid}`,
    {
      credentialName,
      loginName,
      description,
      roleGuid
    }
  );
  return response.guid;
}

export async function changeUserPwd(credentialGuid, oldPassword, newPassword) {
  let response = await post(`/credential/ChangePassword/${credentialGuid}`, {
    oldPassword,
    newPassword
  });
  return response.guid;
}

export async function deleteOneCredential(credentialGuid) {
  let response = await post("/credential/DeleteOneCredential", {
    guid: credentialGuid
  });
  return response.guid;
}
