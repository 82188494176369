export const notFountRoute = {
  path: "*",
  name: "nopage",
  meta: {
    type: "nopage",
    title: "找不到页面了"
  },
  component: () => import(/* webpackChunkName: "nopage" */ "@/views/NoPage.vue")
};

export const staticRoutes = [
  {
    path: "/",
    name: "login",
    meta: {
      type: "adminlogin",
      title: "登录"
    },
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue")
  },
  {
    path: "/noreg",
    name: "noreg",
    props: true,
    meta: {
      type: "noreg",
      title: "未注册"
    },
    component: () => import(/* webpackChunkName: "noreg" */ "@/views/NoReg.vue")
  },
  {
    path: "/scalogin",
    name: "scalogin",
    props: route => ({ scacode: route.query.cd }),
    meta: {
      type: "scalogin",
      title: "测量中心登录"
    },
    component: () =>
      import(/* webpackChunkName: "scalogin" */ "@/views/ScaLogin.vue")
  },
  {
    path: "/sca",
    name: "sca",
    meta: {
      type: "sca"
    },
    component: () =>
      import(/* webpackChunkName: "sca" */ "@/views/sca/ScaApp.vue"),
    children: [
      {
        path: "infoinput",
        name: "scainfoinput",
        meta: {
          type: "sca"
        },
        component: () =>
          import(
            /* webpackChunkName: "scainfoinput" */ "@/views/sca/InfoInput.vue"
          )
      },
      {
        path: "scastepper",
        name: "scastepper",
        meta: {
          type: "sca"
        },
        component: () =>
          import(
            /* webpackChunkName: "scastepper" */ "@/views/sca/ScaStepper.vue"
          )
      },
      {
        path: "scaselect",
        name: "scaselect",
        meta: {
          type: "sca"
        },
        component: () =>
          import(
            /* webpackChunkName: "scaselect" */ "@/views/sca/ScaSelect.vue"
          )
      },
      {
        path: "scatestertext",
        name: "scatestertext",
        meta: {
          type: "sca"
        },
        component: () =>
          import(
            /* webpackChunkName: "scatestertext" */ "@/views/sca/ScaTesterText.vue"
          )
      },
      {
        path: "scareport",
        name: "scareport",
        meta: {
          type: "sca"
        },
        component: () =>
          import(
            /* webpackChunkName: "scareport" */ "@/views/sca/ScaReport.vue"
          )
      }
    ]
  }
];

export const dynamicRoutes = [
  {
    path: "/admin",
    name: "admin",
    meta: {
      type: "admin",
      entities: ["customer", "department"],
      roles: ["admin", "basic", "report"]
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/admin/Admin.vue"),
    children: [
      {
        path: "report",
        name: "report",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-text-box-search",
            title: "案例管理"
          },
          entities: ["customer", "department"],
          roles: ["admin", "basic", "report"]
        },
        component: () =>
          import(
            /* webpackChunkName: "report" */
            "@/views/admin/AdminReport.vue"
          )
      },
      {
        path: "testperson",
        name: "testperson",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-account-multiple",
            title: "被试人员管理"
          },
          entities: ["customer", "department"],
          roles: ["admin", "basic", "report"]
        },
        component: () =>
          import(
            /* webpackChunkName: "testperson" */
            "@/views/admin/AdminTestPerson.vue"
          )
      },
      {
        path: "config",
        name: "configbase",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-wrench",
            title: "测量设置"
          },
          entities: ["customer", "department"],
          roles: ["admin", "basic"]
        },
        component: () =>
          import(
            /* webpackChunkName: "configbase" */
            "@/views/admin/AdminConfig.vue"
          ),
        children: [
          {
            path: "group",
            name: "configgroup",
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-layers",
                title: "测量分组"
              },
              entities: ["customer"],
              roles: ["admin", "basic"]
            },
            component: () =>
              import(
                /* webpackChunkName: "configgroup" */
                "@/views/admin/AdminConfigGroup.vue"
              ),
            children: [
              {
                path: ":guid",
                name: "configgroupdetails",
                props: true,
                meta: {
                  type: "admin",
                  entities: ["customer"],
                  roles: ["admin", "basic"]
                },
                component: () =>
                  import(
                    /* webpackChunkName: "configgroupdetails" */
                    "@/views/admin/AdminConfigGroupDetails.vue"
                  )
              }
            ]
          },
          {
            path: "dept",
            name: "configdept",
            meta: {
              type: "admin",
              navi: {
                iconName: "mdi-domain",
                title: "被试单位"
              },
              entities: ["customer", "department"],
              roles: ["admin", "basic"]
            },
            component: () =>
              import(
                /* webpackChunkName: "configdept" */
                "@/views/admin/AdminConfigDept.vue"
              ),
            children: [
              {
                path: ":guid",
                name: "configdeptdetails",
                props: true,
                meta: {
                  type: "admin",
                  entities: ["customer", "department"],
                  roles: ["admin", "basic"]
                },
                component: () =>
                  import(
                    /* webpackChunkName: "configdeptdetails" */
                    "@/views/admin/AdminConfigDeptDetails.vue"
                  )
              }
            ]
          }
        ]
      },
      {
        path: "lbmanage",
        name: "lbmanage",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-folder-cog",
            title: "量表文档与设置"
          },
          entities: ["customer"],
          roles: ["admin", "basic", "report"]
        },
        component: () =>
          import(
            /* webpackChunkName: "lbmanage" */
            "@/views/admin/AdminLbManage.vue"
          )
      },
      {
        path: "settings",
        name: "settings",
        meta: {
          type: "admin",
          navi: {
            iconName: "mdi-cog",
            title: "设置"
          },
          entities: ["customer"],
          roles: ["admin"]
        },
        component: () =>
          import(
            /* webpackChunkName: "settings" */
            "@/views/admin/AdminSettings.vue"
          )
      }
    ]
  }
];
