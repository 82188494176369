import { get, post } from "@/utils/axios.js";

export async function fetchDeptList(userEntity, userGuid) {
  let response = await post("/department/DepartmentListFromEntity", {
    entityType: userEntity,
    entityGuid: userGuid
  });
  return response.itemList;
}

export async function fetchDeptListFromGroup(testGroupGuid) {
  let response = await get("/department/DepartmentListFromTestGroup", {
    t: new Date().getTime(),
    testGroupGuid
  });
  return response.itemList;
}

export function fetchDeptDetails(deptGuid) {
  return get("/department/DepartmentDetails", {
    t: new Date().getTime(),
    departmentGuid: deptGuid
  });
}

export async function fetchPickableDeptList(userGuid, groupGuid) {
  let response = await get("/groupdept/PickableGroupDepartmentList", {
    t: new Date().getTime(),
    customerGuid: userGuid,
    groupGuid
  });
  return response.itemList;
}

export async function fetchPickedDeptList(groupGuid) {
  let response = await get("/groupdept/PickedGroupDepartmentList", {
    t: new Date().getTime(),
    groupGuid
  });
  return response.itemList;
}

export async function fetchCanStartTestDirectly(deptGuid) {
  let response = await get(
    `/department/CanStartTestDirectlyFromDept/${deptGuid}`
  );
  return response.value;
}

export async function addDeptToGroup(groupGuid, deptGuid) {
  await post(
    `/groupdept/AddDepartmentToGroup/${groupGuid}`,
    JSON.stringify(deptGuid)
  );
}

export async function removeDeptFromGroup(groupGuid, deptGuid) {
  await post(
    `/groupdept/RemoveDepartmentFromGroup/${groupGuid}`,
    JSON.stringify(deptGuid)
  );
}

export async function saveDeptInfoChanges(
  deptGuid,
  { deptName, testeeNumPrefix }
) {
  await post("/department/SaveDepartmentInfo", {
    departmentGuid: deptGuid,
    departmentName: deptName,
    numberPrefix: testeeNumPrefix
  });
}

export async function saveDeptCanStartTestDirectly(deptGuid, canStartTest) {
  await post(`/department/UpdateCanStartTestForDept/${deptGuid}`, {
    value: canStartTest
  });
}

export async function createNewDept(userGuid, { deptName }) {
  let response = await post(`/department/CreateNewDepartment/${userGuid}`, {
    departmentName: deptName
  });
  return response.guid;
}

export async function deleteOneDept(deptGuid) {
  await post("/department/DeleteOneDepartment", JSON.stringify(deptGuid));
}
